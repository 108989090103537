export const SiteIcon = (props) => {
  const { fill = "#98A2B3" } = props;
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>CC6BE3FB-E22E-4F58-90A0-0D4AD17FF921@2x</title>
      <g id="Desktop-" stroke="none" fill="none">
        <g id="0.0_Admin-Portal_Home" transform="translate(-40, -187)">
          <g
            id="03.Components/03.Organisms/01.Menu/01.Default2"
            transform="translate(0, -1)"
          >
            <rect
              id="Rectangle"
              fill="none"
              x="0"
              y="0"
              width="264"
              height="1081"
            ></rect>
            <g id="Group" transform="translate(0, 116)">
              <g
                id="03.Components/01.Atoms/01.Buttons/01.With-Icon/01.Primary/01.Default-Copy-2"
                transform="translate(0, 56)"
              >
                <g id="public_black_24dp" transform="translate(40, 16)">
                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M4,12 C4,11.39 4.08,10.79 4.21,10.22 L8.99,15 L8.99,16 C8.99,17.1 9.89,18 10.99,18 L10.99,19.93 C7.06,19.43 4,16.07 4,12 Z M17.89,17.4 C17.63,16.59 16.89,16 15.99,16 L14.99,16 L14.99,13 C14.99,12.45 14.54,12 13.99,12 L7.99,12 L7.99,10 L9.99,10 C10.54,10 10.99,9.55 10.99,9 L10.99,7 L12.99,7 C14.09,7 14.99,6.1 14.99,5 L14.99,4.59 C17.92,5.77 20,8.65 20,12 C20,14.08 19.19,15.98 17.89,17.4 Z"
                    id="Shape"
                    fill={fill}
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const LanguageIcon = (props) => {
  const { fill = "#98A2B3" } = props;
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>AA18ACA1-83A0-4C56-A256-E2783B299AD2@2x</title>
      <g id="Desktop-" stroke="none" fill="none">
        <g id="0.0_Admin-Portal_Home" transform="translate(-40, -481)">
          <g
            id="03.Components/03.Organisms/01.Menu/01.Default2"
            transform="translate(0, -1)"
          >
            <g id="Group" transform="translate(0, 116)">
              <g
                id="03.Components/01.Atoms/01.Buttons/01.With-Icon/01.Primary/01.Default-Copy-7"
                transform="translate(0, 350)"
              >
                <g id="language_black_24dp" transform="translate(40, 16)">
                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M11.99,2 C6.47,2 2,6.48 2,12 C2,17.52 6.47,22 11.99,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 11.99,2 Z M18.92,8 L15.97,8 C15.65,6.75 15.19,5.55 14.59,4.44 C16.43,5.07 17.96,6.35 18.92,8 Z M12,4.04 C12.83,5.24 13.48,6.57 13.91,8 L10.09,8 C10.52,6.57 11.17,5.24 12,4.04 Z M4.26,14 C4.1,13.36 4,12.69 4,12 C4,11.31 4.1,10.64 4.26,10 L7.64,10 C7.56,10.66 7.5,11.32 7.5,12 C7.5,12.68 7.56,13.34 7.64,14 L4.26,14 Z M5.08,16 L8.03,16 C8.35,17.25 8.81,18.45 9.41,19.56 C7.57,18.93 6.04,17.66 5.08,16 L5.08,16 Z M8.03,8 L5.08,8 C6.04,6.34 7.57,5.07 9.41,4.44 C8.81,5.55 8.35,6.75 8.03,8 L8.03,8 Z M12,19.96 C11.17,18.76 10.52,17.43 10.09,16 L13.91,16 C13.48,17.43 12.83,18.76 12,19.96 Z M14.34,14 L9.66,14 C9.57,13.34 9.5,12.68 9.5,12 C9.5,11.32 9.57,10.65 9.66,10 L14.34,10 C14.43,10.65 14.5,11.32 14.5,12 C14.5,12.68 14.43,13.34 14.34,14 Z M14.59,19.56 C15.19,18.45 15.65,17.25 15.97,16 L18.92,16 C17.96,17.65 16.43,18.93 14.59,19.56 L14.59,19.56 Z M16.36,14 C16.44,13.34 16.5,12.68 16.5,12 C16.5,11.32 16.44,10.66 16.36,10 L19.74,10 C19.9,10.64 20,11.31 20,12 C20,12.69 19.9,13.36 19.74,14 L16.36,14 Z"
                    id="Shape"
                    fill={fill}
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ProfileIcon = (props) => {
  const { fill = "#98A2B3" } = props;
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>511C8FD7-D2A4-494F-82D9-3A3DAD2C31AD@3x</title>
      <g id="Desktop-" stroke="none" fill="none">
        <g id="0.0_Admin-Portal_Home_1" transform="translate(-40, -550)">
          <g id="Group" transform="translate(0, 115)">
            <g
              id="03.Components/01.Atoms/01.Buttons/01.With-Icon/01.Primary/01.Default-Copy-8"
              transform="translate(0, 423)"
            >
              <g id="account_circle_black_24dp" transform="translate(40, 12)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <g id="Group" transform="translate(2, 2)" fill={fill}>
                  <path
                    d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M5.35,16.5 C6.66,15.56 8.26,15 10,15 C11.74,15 13.34,15.56 14.65,16.5 C13.34,17.44 11.74,18 10,18 C8.26,18 6.66,17.44 5.35,16.5 Z M16.14,15.12 C14.45,13.8 12.32,13 10,13 C7.68,13 5.55,13.8 3.86,15.12 C2.7,13.73 2,11.95 2,10 C2,5.58 5.58,2 10,2 C14.42,2 18,5.58 18,10 C18,11.95 17.3,13.73 16.14,15.12 Z"
                    id="Shape"
                  ></path>
                  <path
                    d="M10,4 C8.07,4 6.5,5.57 6.5,7.5 C6.5,9.43 8.07,11 10,11 C11.93,11 13.5,9.43 13.5,7.5 C13.5,5.57 11.93,4 10,4 Z M10,9 C9.17,9 8.5,8.33 8.5,7.5 C8.5,6.67 9.17,6 10,6 C10.83,6 11.5,6.67 11.5,7.5 C11.5,8.33 10.83,9 10,9 Z"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
