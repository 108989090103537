import { SiteIcon, LanguageIcon, ProfileIcon } from "../images/MenuBar/Icons";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  HomeIcon,
  DeviceIcon,
  AlarmIcon,
  UserIcon,
  UsersIcon,
  FileIcon,
  AnalyticsIcon,
} from "@datwyler/shared-components";

export const getNavBarIcon = (item, isSelected, colors) => {
  const iconColor = isSelected ? colors.navBarIconSelected : colors.navBarIcon;

  switch (item) {
    case "home":
      return <HomeIcon fill={iconColor} />;
    case "site":
      return <SiteIcon fill={iconColor} />;
    case "device-monitoring":
      return <DeviceIcon fill={iconColor} />;
    case "alarm":
      return <AlarmIcon fill={iconColor} />;
    case "user":
      return <UserIcon fill={iconColor} />;
    case "tenant":
      return <UsersIcon fill={iconColor} />;
    case "license":
      return <FileIcon fill={iconColor} />;
    case "language":
      return <LanguageIcon fill={iconColor} />;
    case "profile":
      return <ProfileIcon fill={iconColor} />;
    case "telemetry":
      return <TrendingUpIcon sx={{ color: iconColor }} />;
    case "attachments":
      return <AttachFileIcon sx={{ color: iconColor }} />;
    case "analytics":
      return <AnalyticsIcon fill={iconColor} />;
    case "gateway":
      return <DeviceIcon fill={iconColor} />;
    default:
      return null;
  }
};

export const defaultPages = [
  "home",
  "site",
  "attachments",
  "device-monitoring",
  "alarm",
  "user",
  "tenant",
  "license",
  "telemetry",
//  "analytics",
  "gateway",
];

export const settings = ["profile"];
