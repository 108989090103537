import Box from "@mui/material/Box";
import NavBarDrawer from "../components/NavBarDrawer";

const NavBar = (props: any) => {
  const { locale, version } = props;

  return (
    <Box sx={{ display: "flex" }}>
      <NavBarDrawer locale={locale} version={version} />
    </Box>
  );
};

export default NavBar;
