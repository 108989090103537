import { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { ACTIVE } from "../constants";
import {
  BasicSelectWithoutForm,
  useTenant,
  useSubTenant,
} from "@datwyler/shared-components";

// Masquerade as a sub tenant
const MasqSubTenantSelect = (props) => {
  const { user, isOpen, navigate, location } = props;
  const intl = useIntl();
  const { fetchTenantById, fetchTenantByIdData } = useTenant();
  const { fetchSubTenant, fetchSubTenantData } = useSubTenant();
  const [selectedSubTenant, setSelectedSubTenant] = useState(
    location?.state?.tenantId || ""
  );
  const [subTenantOptions, setSubTenantOptions] = useState([]);
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    let isShow = true;
    if (!isOpen) isShow = false;
    else if (location?.pathname === "/device-monitoring/deviceDetails/")
      isShow = false;
    setIsShow(isShow);
  }, [location, isOpen]);

  useEffect(() => {
    fetchSubTenants(user);
    fetchTenant(user);
  }, [user]);

  window.addEventListener("tenantsChanged", () => {
    fetchSubTenants(user);
    fetchTenant(user);
  });

  const fetchSubTenants = (user) => {
    if (user?.tenant?.id) {
      fetchSubTenant({
        variables: {
          tenantId: user.tenant.id,
          page: 0,
          size: 999999,
          filter: [`status:${ACTIVE}`],
          sort: [`name,asc`],
        },
      });
    }
  };

  const fetchTenant = (user) => {
    if (user?.tenant?.id) {
      fetchTenantById({
        variables: {
          id: user.tenant.id,
        },
      });
    }
  };

  useEffect(() => {
    if (fetchTenantByIdData?.tenantById?.id) {
      const subTenantOptions = [
        {
          priority: 0,
          label: fetchTenantByIdData.tenantById.name,
          value: fetchTenantByIdData.tenantById.id,
        },
      ];

      if (fetchSubTenantData?.subTenants?.tenants) {
        // populate the drop down
        const subTenants = fetchSubTenantData?.subTenants?.tenants;

        subTenants.forEach((subTenant, index) => {
          if (subTenant.status === ACTIVE && subTenant.managed) {
            subTenantOptions.push({
              priority: index + 1,
              label: subTenant.name,
              value: subTenant.id,
            });
          }
        });
      }

      setSubTenantOptions(subTenantOptions);
      // set main tenant as default
      if (!selectedSubTenant) {
        setSelectedSubTenant(fetchTenantByIdData.tenantById.id);
      }
    }
  }, [fetchSubTenantData, fetchTenantByIdData]);

  const handleOnChange = (e) => {
    setSelectedSubTenant(e.target.value);
    const state = {
      ...location.state,
      tenantId: e.target.value,
      isMasqAsSubTenant: e.target.value !== fetchTenantByIdData.tenantById.id,
    };
    navigate(location.pathname, { state: state });
  };

  const containerStyle = {
    width: "204px",
    paddingLeft: "40px",
    paddingRight: "16px",
    paddingTop: "10px",
  };

  return (
    <List style={{ padding: 0 }}>
      {isShow && (
        <ListItem
          key={"masqSubTenant"}
          disablePadding
          sx={{ display: "block" }}
        >
          <Box sx={containerStyle}>
            <BasicSelectWithoutForm
              onChange={handleOnChange}
              value={selectedSubTenant}
              label={intl.formatMessage({ id: "menu_select_tenant" })}
              menuItems={subTenantOptions}
              SelectDisplayProps={{
                height: "22px",
                display: "flex",
                alignItems: "end",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
              }}
              menuPropsSx={{
                marginLeft: "24px",
              }}
              menuPaperSx={{
                maxHeight: "128px",
              }}
            />
          </Box>
        </ListItem>
      )}
    </List>
  );
};

export default MasqSubTenantSelect;
