import { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Fab from "@mui/material/Fab";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {
  getNavBarIcon,
  defaultPages as intialPageList,
  settings,
} from "../utils";
import {
  Drawer,
  getListItemButtonStyle,
  getListItemBoxStyle,
  getListItemIconStyle,
  getListItemTextStyle,
} from "../utils/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import LanguageSelect from "../components/LanguageSelect";
import MasqSubTenantSelect from "./MasqSubTenantSelect";
import {
  defaultNavBarWidth,
  getNavBarWidth,
  DatwylerLogo,
  UserContext,
  DarkModeContext,
  hasAccess,
  getScrollBarSx,
} from "@datwyler/shared-components";

const fabDiameter = 36;
const getDefaultIsOpen = () => {
  if (getNavBarWidth() === defaultNavBarWidth) return true;
  return false;
};

const getPermissions = (role) => {
  return intialPageList.filter((page) => hasAccess(role, page));
};

const NavBarDrawer = (props: any) => {
  const theme = useTheme();
  const { locale, version } = props;
  const [isOpen, setIsOpen] = useState(getDefaultIsOpen());
  const navigate = useNavigate();
  const intl = useIntl();
  const { user }: any = useContext(UserContext);
  const defaultPages = getPermissions(user.role);
  const { colors }: any = useContext(DarkModeContext);
  const location = useLocation();
  const [activePage, setActivePage] = useState("home");

  const getLocationState = () => {
    const state = { ...location.state, tenantId: null };
    if (location?.state?.tenantId) {
      // if state already has a tenantId eg. sub tenant id
      state.tenantId = location.state.tenantId;
    } else if (user?.tenant?.id) {
      // if on first load no tenantId in state
      state.tenantId = user.tenant.id;
    }

    return state;
  };

  useEffect(() => {
    if (user?.tenant?.id && location?.pathname && !location?.state?.tenantId) {
      navigate(location.pathname, { state: getLocationState() });
    }
  }, [user, location]);

  useEffect(() => {
    if (location && defaultPages.length > 0) {
      setActivePage(getDefaultPage());
    }
  }, [location, defaultPages]);

  const getDefaultPage = () => {
    if (location.pathname === "/user/profile") return "profile";
    else if (location.pathname === "/tenant/license") return "license";
    else if (location.pathname === "/site/attachments") return "attachments";
    // take the first string AFTER '/'
    const path = location.pathname.split("/")[1];
    if (defaultPages.includes(path) || settings.includes(path)) {
      return path;
    }

    return "home";
  };

  const handleClickDirection = () => {
    if (isOpen) {
      // get the navbar width from theme
      const spacingString = theme.spacing(8);
      const spacing = parseInt(
        spacingString.substring(0, spacingString.indexOf("px"))
      );
      const navBarWidth = spacing + 1;

      window.localStorage.setItem("navBarWidth", navBarWidth.toString());
    } else {
      window.localStorage.setItem("navBarWidth", defaultNavBarWidth);
    }
    setIsOpen(!isOpen);
    window.dispatchEvent(new Event("navBarChanged"));
  };

  const DrawerHeader = () => {
    return (
      <Box sx={{ backgroundColor: colors.navBarBg }}>
        <img
          src={DatwylerLogo}
          style={{
            width: 191,
            height: 35,
            padding: isOpen ? "40px 36px" : "40px 16px",
          }}
          alt="datwyler logo"
        />
      </Box>
    );
  };

  const Version = () => {
    return (
      <ListItem
        key={"masqSubTenant"}
        disablePadding
        sx={{ display: "block", position: "absolute", top: "76px" }}
      >
        <Box
          sx={{
            background: "transparent",
            textAlign: "right",
            paddingRight: "16px",
            paddingTop: "4px",
          }}
        >
          <Typography
            sx={{
              fontSize: 12,
              fontFamily: "NotoSans-Regular",
              color: colors.versionNumber,
            }}
          >
            {version}
          </Typography>
        </Box>
      </ListItem>
    );
  };

  const handleMenuClick = (page) => {
    const state = getLocationState();

    setActivePage(page);
    if (page === "user") {
      navigate("/user", { state: state });
    } else if (page === "profile") {
      navigate("/user/profile", { state: state });
    } else if (page === "license") {
      navigate("/tenant/license", { state: state });
    } else if (page === "attachments") {
      navigate("/site/attachments", { state: state });
    } else {
      navigate(page, { state: state });
    }
  };

  const getList = (list: any) => {
    return (
      <List style={{ padding: 0 }}>
        {list.map((text) => {
          return (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => handleMenuClick(text)}
                sx={getListItemButtonStyle(isOpen, colors)}
              >
                <Box
                  sx={getListItemBoxStyle(text === activePage, isOpen, colors)}
                >
                  <ListItemIcon sx={getListItemIconStyle(isOpen)}>
                    {getNavBarIcon(text, text === activePage, colors)}
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage({ id: "menu_" + text })}
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontFamily:
                        text === activePage
                          ? "NotoSans-SemiBold"
                          : "NotoSans-Medium",
                    }}
                    sx={getListItemTextStyle(
                      text === activePage,
                      isOpen,
                      colors
                    )}
                  />
                </Box>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Fab
        onClick={handleClickDirection}
        disableRipple
        sx={{
          width: fabDiameter,
          height: fabDiameter,
          background: colors.navBarFab,
          position: "fixed",
          left: isOpen ? defaultNavBarWidth - fabDiameter / 2 : 48,
          top: 40,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: theme.transitions.create("left", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          ":hover": {
            backgroundColor: colors.navBarFabHover,
          },
        }}
      >
        {isOpen ? (
          <KeyboardDoubleArrowLeftIcon style={{ color: colors.navBarArrow }} />
        ) : (
          <KeyboardDoubleArrowRightIcon style={{ color: colors.navBarArrow }} />
        )}
      </Fab>

      <Drawer
        variant="permanent"
        open={isOpen}
        PaperProps={{ sx: { backgroundColor: colors.navBarBg } }}
      >
        <DrawerHeader />
        <Version />
        <Box
          sx={{
            ...getScrollBarSx(colors),
            maxHeight: `calc(100vh-160)`,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {getList(defaultPages)}
          <Divider sx={{ borderColor: colors.dividerColor }} />
          <LanguageSelect locale={locale} isNavBarOpen={isOpen} />
          {getList(settings)}
          <MasqSubTenantSelect
            user={user}
            isOpen={isOpen}
            navigate={navigate}
            location={location}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default NavBarDrawer;
