import React, { useState } from "react";
import App from "./App";
import "./main.css";
import { BrowserRouter } from "react-router-dom";
import {
  useLocale,
  TranslationProvider,
  ApiProvider,
  GraphQLApiProvider,
  DarkModeProvider,
} from "@datwyler/shared-components";

export default function Root(props: any) {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);

  return (
    <React.StrictMode>
      <ApiProvider uri={props.SECURED_GATEWAY_URL}>
        <GraphQLApiProvider uri={props.GRAPHQL_URL}>
          <BrowserRouter>
            <TranslationProvider locale={locale}>
              <DarkModeProvider>
                <App locale={locale} version={props.RELEASE_VERSION} />
              </DarkModeProvider>
            </TranslationProvider>
          </BrowserRouter>
        </GraphQLApiProvider>
      </ApiProvider>
    </React.StrictMode>
  );
}
