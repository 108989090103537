import { useState, useContext } from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { getNavBarIcon } from "../utils";
import {
  getListItemButtonStyle,
  getListItemBoxStyle,
  getListItemIconStyle,
  getListItemTextStyle,
  getLanguageBoxStyle,
} from "../utils/styles";
import {
  LOCALES,
  useLocale,
  DarkModeContext,
  ArrowRightIcon,
  CheckBlackIcon,
} from "@datwyler/shared-components";

const LanguageSelect = (props: any) => {
  const { locale, isNavBarOpen } = props;
  const intl = useIntl();
  const { setLocale } = useLocale();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isActive, setIsActive] = useState(false);
  const { colors }: any = useContext(DarkModeContext);
  let isMouseInside = false;

  const text = "language";

  const handleChange = (loc: String) => {
    setLocale(loc);
  };

  const handleOpenLanguageSelect = (event) => {
    setIsActive(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsActive(false);
    setAnchorEl(null);
  };

  const handleCloseFromOutside = (event) => {
    setTimeout(() => {
      if (!isMouseInside) handleClose();
    }, 200);
  };

  const handleMouseEnterPopover = () => {
    isMouseInside = true;
  };

  const handleMouseleavePopover = () => {
    isMouseInside = false;
  };

  return (
    <ListItem key={"language_select"} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        id="language_btn"
        sx={getListItemButtonStyle(isNavBarOpen, colors)}
        onClick={handleOpenLanguageSelect}
        onMouseEnter={handleOpenLanguageSelect}
        onMouseLeave={handleCloseFromOutside}
      >
        <Box sx={getListItemBoxStyle(false, isNavBarOpen, colors)}>
          <ListItemIcon sx={getListItemIconStyle(isNavBarOpen)}>
            {getNavBarIcon(text, isActive, colors)}
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({ id: "menu_" + text })}
            primaryTypographyProps={{
              fontSize: 16,
              fontFamily: isActive ? "NotoSans-SemiBold" : "NotoSans-Medium",
            }}
            sx={getListItemTextStyle(isActive, isNavBarOpen, colors)}
          />
        </Box>
        <ArrowRightIcon />
      </ListItemButton>
      <Popover
        id="language_popover"
        onClose={handleClose}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onMouseEnter={handleMouseEnterPopover}
        onMouseLeave={handleMouseleavePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          marginLeft: 1,
          pointerEvents: "none",
        }}
        PaperProps={{
          onMouseLeave: handleClose,
          sx: {
            pointer: "cursor",
            pointerEvents: "auto",
            borderRadius: "8px",
            backgroundColor: colors.selectDropDownBg,
          },
        }}
      >
        <List component="div" disablePadding>
          {Object.keys(LOCALES).map((lang, index) => {
            const loc = LOCALES[lang];

            return (
              <ListItemButton
                key={"listButtonItem_" + index}
                onClick={() => handleChange(loc)}
                sx={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: index === 0 ? "8px" : "4px",
                  paddingBottom: index === LOCALES.length - 1 ? "8px" : "4px",
                }}
              >
                <Box sx={getLanguageBoxStyle(locale === loc, colors)}>
                  <ListItemText
                    primary={intl.formatMessage({ id: loc })}
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontFamily: "NotoSans-Medium",
                    }}
                    sx={{
                      minWidth: 160,
                      opacity: anchorEl ? 1 : 0,
                      color: colors.selectDropDownFont,
                      margin: 0,
                    }}
                  />
                  {locale === loc && (
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <CheckBlackIcon />
                    </ListItemIcon>
                  )}
                </Box>
              </ListItemButton>
            );
          })}
        </List>
      </Popover>
    </ListItem>
  );
};

export default LanguageSelect;
