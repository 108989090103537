import { Route, Routes } from "react-router-dom";
import NavBar from "./containers/NavBar";

const Router = (props: any) => {
  const { locale, version } = props;

  return (
    <Routes>
      <Route path="/*" element={<NavBar locale={locale} version={version} />} />
    </Routes>
  );
};

export default Router;
