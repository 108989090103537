import Router from "./Router";
import { UserProvider } from "@datwyler/shared-components";

const App = (props: any) => {
  const { locale, version } = props;

  return (
    <UserProvider>
      <Router locale={locale} version={version} />
    </UserProvider>
  );
};

export default App;
