import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { defaultNavBarWidth } from "@datwyler/shared-components";

const openedMixin = (theme: Theme): CSSObject => ({
  width: defaultNavBarWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: defaultNavBarWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const getListItemButtonStyle = (isOpen, colors) => ({
  minHeight: 48,
  justifyContent: isOpen ? "initial" : "center",
  paddingLeft: isOpen ? "22px" : 2,
  paddingRight: isOpen ? "16px" : 2,
  py: 0,
  "&:hover": {
    backgroundColor: colors.navbarSelected,
  },
});

export const getListItemBoxStyle = (isActive, isOpen, colors) => ({
  backgroundColor: isActive ? colors.navbarSelected : "unset",
  display: "flex",
  py: "10px",
  paddingLeft: isOpen ? "18px" : "6px",
  width: "100%",
  height: "100%",
  borderRadius: 2,
});

export const getListItemIconStyle = (isOpen) => ({
  minWidth: 0,
  mr: isOpen ? 1 : "auto",
  justifyContent: "center",
  alignItems: "center",
});

export const getListItemTextStyle = (isActive, isOpen, colors) => ({
  marginTop: 0,
  marginBottom: 0,
  opacity: isOpen ? 1 : 0,
  color: isActive ? colors.navBarFontSelected : colors.navBarFont,
});

export const getLanguageBoxStyle = (isSelected, colors) => ({
  backgroundColor: isSelected ? colors.navbarSelected : "unset",
  display: "flex",
  py: "10px",
  px: "16px",
  width: "100%",
  height: "100%",
  borderRadius: 2,
});
